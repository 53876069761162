import dayjs from "dayjs";
import { TimeSpan } from "../TimeSpan";

export interface SceneModel {
    activeScenes: SceneInfo[],
}

export interface SceneInfo {
    scene: Scene,
    status: string,
}

export enum Scene {
    Maid = "Maid",
    Bedtime = "Bedtime",
}

export interface IAutomationModel {
    id: string;
    title: string;
    order: number;
    status: string;
    type: AutomationType;
    isPaused: boolean;
    isInactive: boolean;
    dashboards: string[];
    irSensors?: Record<string, string>;
    settings: AutomationSettings;
}

export interface AutomationSettings {
    id: string;
    commandsToLearn?: Record<string, SetpointSetting>;
    irCommands: Record<string, Record<string, SetpointSetting>>;
}

export interface IClimateAutomationModel extends IAutomationModel {
    brightness: number;
    targetTemp: number;
    targetTempLow: number;
    targetTempHigh: number;
    currentTemperature: number;
    operationMode: OperationMode;
    operatingState: string;
    holdEndTime?: string | null;
}

export interface ILightAutomationModel extends IAutomationModel {
    isOn: boolean;
    isAvailable: boolean;
    showComponents: boolean;
    components: LightComponentState[];
    brightness: number;
    temperature: number;
    supportedMoods: LightMood[];
    colorGroups: ColorGroup[];
    mood: LightMood;
    hexColor: string | null;
    isColor: boolean;
    hasColorTemp: boolean;
    canDim: boolean;
    holdDurationSeconds: number;
    holdEndTime?: string;
    fanState?: FanDeviceState;
    allowTemporarySetting: boolean;
    supportsDeltas: boolean;
}

export interface FanDeviceState {
    entityId: string;
    isOn: boolean;
    isAvailable: boolean;
    supportedSpeeds?: number[];
    currentSpeed?: number;
}

export interface LightComponentState {
    entityId: string;
    name: string;
    isOn: boolean;
    brightness: number;
}

export interface ColorGroup {
    name: string,
    colors: NamedColor[],
}

export interface NamedColor {
    name: string;
    hexColor: string;
}

export interface IWeatherAutomationModel extends IAutomationModel {
    today: Forecast;
    days: Forecast[];
    now: WeatherPoint;
    updateTime: string;
}

export interface Forecast {
    temperature: number;
    minTemperature: number;
    maxTemperature: number;
    dataPoints: WeatherPoint[];
    description: string;
    sunrise: string;
    sunset: string;
}

export interface WeatherPoint {
    temperature: number;
    timestamp: string;
    rain: number;
    precipitationPercent: number;
    cloudCoveragePercent: number;
    description: string;
}

export interface ILockAutomationModel extends IAutomationModel {
    lockStatus: LockStatus;
    batteryLevel?: number | null;
    doorIsOpen?: boolean;
}

export interface IGarageAutomationModel extends IAutomationModel {
    doorStatus: CoverStatus;
    motionDetected: boolean;
    isObstructed: boolean;
    lightIsOn: boolean;
    position: number;
    interiorDoor?: ContactSensorState;
}

export interface IBlindsAutomationModel extends IAutomationModel {
    tiltPosition: number;
    mainStatus: BlindsStatus;
    angle: number;
    blinds: IBlindsState[];
}

export interface IBlindsState {
    coverEntityId?: string;
    contactEntityId?: string;
    name: string;
    status: BlindsStatus;
    tiltPosition?: number;
    battery?: number;
    angle?: number;
    windowIsOpened?: boolean;
    windowTemperature?: number;
    windowHumidity?: number;
    windowPressure?: number;
}

export interface IMediaPlayerAutomationModel extends IAutomationModel {
    mediaState: string;
    appName: string;
    mediaDuration: number | null;
    mediaPosition: number | null;
    volumeLevel: number;
    actions?: MediaPlayerAction[];
    thumbnail?: string;
}

export interface MediaPlayerAction {
    actionName: string;
    id: string;
}

export interface IHealthCheckState extends IAutomationModel {
    devices: HealthCheckDevice[];
}

export interface HealthCheckDevice {
    id: string;
    name: string;
    isConnected: boolean;
}

export interface ITemperatureAutomationState extends IAutomationModel {
    temperatures: ITemperatureState[];
}

export interface ITemperatureState {
    name: string;
    temperature?: number | null;
}

export interface IPlantOverviewAutomationState extends IAutomationModel {
    plants: IPlantState[];
}

export interface IPlantState {
    entityId: string;
    name: string;
    waterLevel: number;
    waterMinimum: number;
    waterMaximum: number;
    isAvailable: boolean;
    needsWater: boolean;
    status?: string;
    history: IPlantHistory[];
}

export interface IPlantAutomationState extends IAutomationModel {
    plant: IPlantState;
}

export interface IPlantHistory {
    waterLevel: number;
    timestamp: string;
}

export interface IPresenceState extends IAutomationModel {
    connectedUsers: IConnectedUser[];
}

export interface IConnectedUser {
    name: string;
    isConnected: boolean;
}

export interface ITimersAutomationModel extends IAutomationModel {
    runningTimers: TimerState[];
    timers: TimerState[];
}

export interface TimerState {
    id: string;
    message: string;
    endTime: string;
}

export interface IBatteryAutomationState extends IAutomationModel {
    batteries: IBatteryState[];
}

export interface IBatteryState {
    name: string;
    percent?: number | null;
}

export interface IPrinterAutomationModel extends IAutomationModel {
    plugState?: PlugState;
    isActive: boolean;
    currentStage?: string;
    currentLayer?: number;
    totalLayerCount?: number;
    bedTemperature?: number;
    nozzleTemperature?: number;
    printLength?: number;
    remainingTime?: string;
    printProgress?: number;
    endTime?: string;
    lightIsOn: boolean;
    totalHours?: number;
    humidity?: number;
}

export interface IAlertsAutomationModel extends IAutomationModel {
    alerts: Alert[];
}

export interface Alert {
    id: string;
    title: string;
    status: string;
    prettyTime: string;
    canResolve: boolean;
    resolveActionType: ResolveActionType;
}

export enum ResolveActionType {
    None = 'None',
    Snooze = 'Snooze',
    Bedtime = 'Bedtime',
    Cancel = 'Cancel',
}

export interface IPlugsAutomationModel extends IAutomationModel {
    plugs: PlugState[];
}

export interface PlugState {
    entityId: string;
    name: string;
    isOn: boolean;
    isAvailable: boolean;
    amps: number;
    watts: number;
    kwh: number;
    cost: number;
    onSince: string;
}

export interface IRemotesAutomationModel extends IAutomationModel {
}

export interface IContactSensorsAutomationModel extends IAutomationModel {
    sensors: ContactSensorState[];
}

export interface ICatFeederAutomationModel extends IAutomationModel {
    isAvailable: boolean;
    totalFoodDrops?: number;
    isJammed: boolean;
    isEmpty: boolean;
    jamTime: string,
}

export interface ContactSensorState {
    entityId: string;
    isAvailable: boolean;
    friendlyName?: string;
    hasContact?: boolean;
    temperature?: number;
    humidity?: number;
    pressure?: number;
}

export interface IScenesAutomationModel extends IAutomationModel {
    modes: string[];
    currentMode: string;
}

export enum AutomationType {
    Lights = 'Lights',
    Climate = 'Climate',
    Weather = 'Weather',
    Lock = 'Lock',
    GarageDoor = 'GarageDoor',
    Temperature = 'Temperature',
    MediaPlayer = 'MediaPlayer',
    Alerts = 'Alerts',
    Battery = 'Battery',
    Timers = 'Timers',
    Plugs = 'Plugs',
    Blinds = 'Blinds',
    Cameras = 'Cameras',
    Presence = 'Presence',
    HealthCheck = 'HealthCheck',
    PlantOverview = 'PlantOverview',
    Plant = 'Plant',
    Printer = 'Printer',
    ContactSensors = 'ContactSensors',
    Remotes = 'Remotes',
    CatFeeder = 'CatFeeder',
}

export enum LockStatus {
    Unknown = "Unknown",
    Locked = "Locked",
    Unlocked = "Unlocked",
}

export enum BlindsStatus {
    Unknown = "Unknown",
    None = "None",
    Up = "Up",
    Down = "Down",
    Open = "Open",
    Moving = "Moving"
}
export enum CoverStatus {
    Unknown = "Unknown",
    Closed = "Closed",
    Open = "Open",
    Opening = "Opening",
    Closing = "Closing"
}

export enum OperationMode {
    Off = "Off",
    Heat = "Heat",
    Cool = "Cool",
    Auto = "Auto",
    Aux = "Aux",
}

export enum LightMood {
    None = 'None',
    Night = 'Night',
    Dimmed = 'Dimmed',
    Chill = 'Chill',
    Default = 'Default',
    Focus = 'Focus',
    Tropical = 'Tropical',
    Boo = 'Boo',
}

export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

export enum TimeType {
    TimeOfDay = 'TimeOfDay',
    Sunrise = 'Sunrise',
    Sunset = 'Sunset',
}

export interface ScheduleConfigModel {
    id: string,
    title: string,
    groupType: GroupType,
    days: DayConfigModel[],
}

export interface DayConfigModel {
    dayOfWeek: DayOfWeek,
    settings: SetpointSetting[],
    isActive: boolean,
}

export interface SetpointSetting {
    $type: string,
    id: string,
    milliseconds: number,
    oneTime?: boolean,
    timeOfDay?: string,
    timeType?: TimeType,
    isActive?: boolean,
    isDeltaChange?: boolean,
    mood?: LightMood,
}

export interface ClimateSetpoint extends SetpointSetting {
    min: number,
    max: number,
}

export interface LightSetpoint extends SetpointSetting {
    target: LightTarget;
    isOn: boolean,
    brightness?: number | null,
    fanSpeed?: number;
}

export enum LightTarget {
    Lights = 'Lights',
    Fan = 'Fan',
    All = 'All',
}

export interface BlindsSetpoint extends SetpointSetting {
    position: number,
}

export enum PlugMode {
    Off = 'Off',
    On = 'On',
    Toggle = 'Toggle',
}

export interface PlugSetpoint extends SetpointSetting {
    plugId: string,
    mode: PlugMode,
}

export interface MediaPlayerSetpoint extends SetpointSetting {
    operation: MediaPlayerOperation;
    operationData: string;
}

export enum MediaPlayerOperation {
    Off = 'Off',
    On = 'On',
    Action = 'Action',
    Play = 'Play',
    Pause = 'Pause',
}

export interface SceneSetpoint extends SetpointSetting {
    scene: Scene;
}

export interface ICameraModel {
    title: string,
    id: string,
    url: string,
    hasFiles: boolean,
    canCast: boolean,
}

export interface MediaList {
    items: MediaFile[]
}

export interface MediaFile {
    timestamp: string,
    prettyTimestamp: string,
    fileName: string,
    isNew: boolean,
}

export interface IEventLogData {
    message: string,
    timestamp: string,
}

export interface RouteInfo {
    Name: string,
    Path: string,
    Icon?: JSX.Element,
    ShowInTop?: boolean,
    Meta?: any,
    Color?: string,
}

export interface SettingsModel {
    supportedModes: string[],
    mode: string,
}

export enum GroupType {
    None = "None",
    WeekendsWeekdays = "WeekendsWeekdays",
    All = "All",
}

export interface ESModel {
    timestamp: string,
    name: string,
    entityId: string,
}
export interface ESThermostat extends ESModel {
    minTemperature?: number,
    maxTemperature?: number,
    currentTemperature?: number,
    operatingState?: string,
    fanState?: string,
    isOn: boolean,
}

export interface ESLight extends ESModel {
    brightness?: number,
    isOn?: boolean,
}

export interface ESContactSensor extends ESModel {
    isOn?: number,
    temperature?: number,
    humidity?: number,
}

export interface ESCatFeeder extends ESModel {
    unavailable: number,
    isEmpty: number,
    isJammed: number,
    totalFoodDrops: number,
    servingsLeft: number,
}

export interface ESPlant extends ESModel {
    waterLevel?: number,
    waterMinimum?: number,
}

export interface Watchlist {
    items: MediaItem[];
}

export interface MediaItem {
    title: string;
    id: string;
    runtime?: string;
    year?: string;
    poster?: string;
    dddInfo?: DddInfo;
}

export interface DddInfo {
    id?: string;
    topicItemStats?: DddTopic[];
}

export interface DddTopic {
    topicItemId: number;
    doesName: string;
    yesSum: number;
    noSum: number;
    isFavorite: boolean;
    comment?: string;
}
