import { DeleteTwoTone } from "@mui/icons-material";
import { Badge, Box, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, MenuItem, Modal, Select, Slider, Stack, Switch, SxProps, Theme, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import { BlindsSetpoint, ClimateSetpoint, DayOfWeek, IAutomationModel, ILightAutomationModel, IMediaPlayerAutomationModel, IPlugsAutomationModel, LightMood, LightSetpoint, LightTarget, MediaPlayerOperation, MediaPlayerSetpoint, PlugMode, PlugSetpoint, Scene, SceneSetpoint, SetpointSetting, TimeType } from "../../models/Models";
import { TimeSpan } from "../../TimeSpan";
import { DeltaChange } from "../controls/DeltaChange";
import * as Grey from "../StyledComponents";

export interface ISetpointProps {
    automation: IAutomationModel,
    dayOfWeek?: DayOfWeek,
    data: SetpointSetting,
    hideTime?: boolean,
    updateSetting: (value: SetpointSetting) => void,
    deleteTime?: (setting: SetpointSetting) => void,
    orientation?: 'horizontal' | 'vertical',
}

interface IProps {
    automation: IAutomationModel,
    dayOfWeek?: DayOfWeek,
    data: SetpointSetting,
    hideTime?: boolean,
    updateSetting: (value: SetpointSetting) => void,
    deleteTime?: (setting: SetpointSetting) => void,
    orientation?: 'horizontal' | 'vertical',
}

export function SetpointSettingControl(props: ISetpointProps) {
    if (props.data.$type == "Climate") {
        return ClimateSetting({ ...props, data: props.data as ClimateSetpoint, updateSetting: props.updateSetting });
    }
    else if (props.data.$type == "Lights") {
        return LightSetting({ ...props, data: props.data as LightSetpoint, updateSetting: props.updateSetting });
    }
    else if (props.data.$type == "Blinds") {
        return BlindsSetting({ ...props, data: props.data as BlindsSetpoint, updateSetting: props.updateSetting });
    }
    else if (props.data.$type == "MediaPlayer") {
        return MediaPlayerSetting({ ...props, data: props.data as MediaPlayerSetpoint, updateSetting: props.updateSetting });
    }
    else if (props.data.$type == "Plug") {
        return PlugSetting({ ...props, data: props.data as PlugSetpoint, updateSetting: props.updateSetting });
    }
    else if (props.data.$type == "Scene") {
        return SceneSetting({ ...props, data: props.data as SceneSetpoint, updateSetting: props.updateSetting });
    }
    else {
        return <Typography>Unknown type '{props.data.$type}'</Typography>
    }
}

export function GetSetpointDescriptionWithTime(automation: IAutomationModel, s: SetpointSetting) {
    return `${GetFormattedTimeLabel(s)} ${GetSetpointDescription(automation, s)}`;
}

export function GetSetpointDescription(automation: IAutomationModel, s: SetpointSetting) {
    if (s.$type === "Lights") {
        const lights = s as LightSetpoint;
        if (lights.target === LightTarget.Fan) {
            if (lights.isDeltaChange) {
                if ((lights.fanSpeed ?? 0) >= 0) {
                    return `Turn fan up ${lights.fanSpeed}`;
                }
                else {
                    return `Turn fan down ${Math.abs(lights.fanSpeed ?? 0)}`;
                }
            }
            if ((lights.fanSpeed ?? 0) == 0) {
                return 'Turn fan off';
            }
            return `Turn fan to ${lights.fanSpeed ?? 0}`;
        }
        if (lights.target === LightTarget.Lights) {
            if (lights.isDeltaChange) {
                if ((lights.brightness ?? 0) > 0) {
                    return `Turn lights up ${lights.brightness}%`;
                }
                else {
                    return `Turn lights down ${Math.abs(lights.brightness ?? 0)}%`;
                }
            }

            if (lights.mood && lights.mood !== LightMood.None) {
                return `Set lights to ${lights.mood}`;
            }

            return `Change brightness to ${lights.brightness ?? 0}%`;
        }
        if (lights.target === LightTarget.All) {
            if (lights.isOn) {
                return 'Turn everything on';
            }
            return 'Turn everything off';
        }
    }
    if (s.$type === 'Climate') {
        const climate = s as ClimateSetpoint;
        return `Low: ${climate.min}, High: ${climate.max}`;
    }
    if (s.$type === 'MediaPlayer') {
        const mp = s as MediaPlayerSetpoint;
        const mpAutomation = automation as IMediaPlayerAutomationModel;
        if (mp.operation === MediaPlayerOperation.Action) {
            const action = mpAutomation.actions?.find(i => i.id === mp.operationData);
            if (action) {
                return `Play ${action.actionName}`;
            }
        }
        return `Operation: ${mp.operation}`;
    }
    if (s.$type === 'Blinds') {
        const blinds = s as BlindsSetpoint;
        return `${blinds.position}%`;
    }
    if (s.$type === 'Scene') {
        const scene = s as SceneSetpoint;
        return `Action: ${scene.scene}`;
    }
    if (s.$type === 'Plug') {
        const plugAutomation = automation as IPlugsAutomationModel;
        const plug = s as PlugSetpoint;
        const plugName = plugAutomation.plugs?.find(i => i.entityId === plug.plugId)?.name ?? plug.plugId;
        switch (plug.mode) {
            case PlugMode.Toggle:
                return `Toggle ${plugName}`
            default:
                return `Turn ${plug.mode} ${plugName}`
        }
    }

    return s.$type;
}

const GetFormattedTimeLabel = (data: SetpointSetting) => {
    const t = new TimeSpan(data.milliseconds).toShortString();
    if (data.timeType == TimeType.Sunrise) {
        return `${t} (sunrise)`;
    }
    if (data.timeType == TimeType.Sunset) {
        return `${t} (sunset)`;
    }
    return t;
}

function TimeSetting(props: {
    data: SetpointSetting,
    setData: (d: SetpointSetting) => void,
    updateSetting: (value: SetpointSetting) => void,
    sx?: SxProps<Theme>,
    deleteTime?: (d: SetpointSetting) => void,
}) {
    const theme = useTheme();
    const [openDelete, setOpenDelete] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);

    const handleOpen = () => {
        setOpenDelete(true);
    };

    const handleClose = () => {
        setOpenDelete(false);
    };

    const handleDelete = () => {
        setOpenDelete(false);
        if (props.deleteTime) {
            props.deleteTime(props.data);
        }
    };

    return (
        <Box sx={{ ...props.sx, minWidth: '150px' }}>
            <Badge
                color='secondary'
                variant={props.data.oneTime ? 'dot' : 'standard'}
                title={props.data.oneTime ? 'runs only once' : ''}>
                <Grey.CustomButton disabled={props.data.oneTime === true} variant="contained" onClick={() => setOpenModal(true)}>
                    <Typography color={props.data.isActive ? "#37ad67" : ''}>{GetFormattedTimeLabel(props.data)}</Typography>
                </Grey.CustomButton>
            </Badge>
            {props.deleteTime ? <IconButton onClick={handleOpen}><DeleteTwoTone /></IconButton> : null}
            <TimeModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                data={props.data}
                setData={d => props.setData(d as ClimateSetpoint)}
                updateSetting={props.updateSetting} />
            <Dialog
                open={openDelete}
                onClose={handleClose}>
                <DialogTitle id="alert-dialog-title">
                    {"Delete?"}
                </DialogTitle>
                <DialogContent>
                </DialogContent>
                <DialogActions>
                    <Grey.CustomButton onClick={handleClose}>Cancel</Grey.CustomButton>
                    <Grey.CustomButton onClick={handleDelete} autoFocus>Delete</Grey.CustomButton>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

function TimeModal(props: {
    data: SetpointSetting,
    setData: (d: SetpointSetting) => void,
    open: boolean,
    onClose: () => void,
    updateSetting: (value: SetpointSetting) => void,
}) {
    const [data, setData] = useState(props.data);
    const [timeType, setTimeType] = useState(data.timeType ?? TimeType.TimeOfDay);
    const [milliseconds, setMilliseconds] = useState(data.milliseconds);

    const getTimeOfDay = (ms: number) => {
        if (isNaN(ms)) {
            return "00:00:00";
        }

        const totalMinutes = ms / 1000 / 60;
        const hrs = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes - (hrs * 60);
        return `${hrs < 10 ? '0' + hrs : hrs}:${minutes < 10 ? '0' + minutes : minutes}:00`;
    }

    const changeTime = (value: Dayjs | null) => {
        if (value) {
            const ms = (value.hour() * 3600000) + (value.minute() * 60000);
            setMilliseconds(isNaN(ms) ? 0 : ms);
            setTimeType(TimeType.TimeOfDay);
            setData({ ...data, milliseconds: ms, timeType: TimeType.TimeOfDay })
        }
    }
    const saveTime = () => {
        setData({ ...data, milliseconds: milliseconds, timeOfDay: getTimeOfDay(milliseconds), timeType });
        props.updateSetting({ ...data, milliseconds: milliseconds, timeOfDay: getTimeOfDay(milliseconds), timeType: timeType })
        props.onClose();
    }

    return <Modal
        open={props.open}
        onClose={props.onClose}>
        <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            width: '300px',
            transform: 'translate(-50%, -50%)',
            border: '2px solid #000',
            boxShadow: 24,
            p: 0,
        }}>
            <Card>
                <CardContent>
                    <Typography sx={{ fontSize: '30px', marginLeft: '10px' }} >
                        {GetFormattedTimeLabel(data)}
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <MultiSectionDigitalClock defaultValue={dayjs(data.timeOfDay, 'HH:mm:ss')} onChange={changeTime} timeSteps={{ minutes: 5 }} />
                    </LocalizationProvider>
                    <Grey.CustomButton onClick={() => { setTimeType(TimeType.Sunrise); setData({ ...data, timeType: TimeType.Sunrise }) }}>Sunrise</Grey.CustomButton>
                    <Grey.CustomButton onClick={() => { setTimeType(TimeType.Sunset); setData({ ...data, timeType: TimeType.Sunset }) }}>Sunset</Grey.CustomButton>
                    <Grey.CustomButton variant='contained' sx={{ float: 'right', marginY: '25px' }} onClick={saveTime}>Save</Grey.CustomButton>
                </CardContent>
            </Card>
        </Box>
    </Modal>
}

const ClimateSetting: React.FC<IProps> = (props: IProps) => {
    const [data, setData] = useState(props.data as ClimateSetpoint);

    const [range, setRange] = useState([data.min, data.max]);
    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        var dataArray = newValue as number[];
        setRange(dataArray);
    }

    const commitChange = async (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
        var dataArray = newValue as number[];
        props.updateSetting({ ...data, min: dataArray[0], max: dataArray[1] } as ClimateSetpoint)
    }

    const marks = [
        {
            value: 65,
            label: '65°'
        },
        {
            value: 80,
            label: '80°'
        },
        {
            value: data.min,
            label: data.min + '°'
        },
        {
            value: data.max,
            label: data.max + '°'
        }
    ];

    return <Box sx={{ marginRight: '25px' }}>
        {!props.hideTime ?
            <TimeSetting
                sx={{ marginY: '10px' }}
                data={data}
                setData={d => setData(d as ClimateSetpoint)}
                deleteTime={props.deleteTime}
                updateSetting={d => { setData(d as ClimateSetpoint); props.updateSetting(d); }} /> : null}
        <Slider
            color="primary"
            min={65}
            max={80}
            orientation="vertical"
            step={1}
            marks={marks}
            value={range}
            sx={{
                height: '280px',
                marginTop: .5,
                '& .MuiSlider-markLabel[data-index="2"]': { color: '#4ac6ff' },
                '& .MuiSlider-markLabel[data-index="3"]': { color: '#f79d6d' }
            }}
            onChange={handleSliderChange}
            onChangeCommitted={commitChange}
            valueLabelDisplay="auto"
        />
    </Box>;
}

const LightSetting: React.FC<IProps> = (props: IProps) => {
    const automation = props.automation as ILightAutomationModel;
    const theme = useTheme();
    const [data, setData] = useState(props.data as LightSetpoint);

    const [target, setTarget] = useState(data.target);
    const [mood, setMood] = useState(data.mood);
    const [range, setRange] = useState([data.brightness ?? 0]);
    const [fanSpeed, setFanSpeed] = useState(data.fanSpeed ?? 0);
    const [isDeltaChange, setIsDeltaChange] = useState(data.isDeltaChange);
    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        var dataArray = newValue as number[];
        setRange(dataArray);
    }

    const commitSliderChange = async (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
        var dataArray = newValue as number[];
        setData({ ...data, brightness: dataArray[0], isOn: dataArray[0] !== 0 });
        props.updateSetting({ ...data, brightness: dataArray[0], isOn: dataArray[0] !== 0 } as LightSetpoint)
    }

    const commitChange = (n: {}) => {
        setData({ ...data, ...n });
        props.updateSetting({ ...data, ...n })
    }

    const marks = [
        {
            value: 0,
            label: '0%'
        },
        {
            value: 100,
            label: '100%'
        },
        {
            value: range[0],
            label: `${range[0]}%`
        }
    ];

    const changeTarget = (event: React.MouseEvent<HTMLElement>,
        newValue: LightTarget | null) => {
        setTarget(newValue ?? LightTarget.Lights);
        setFanSpeed(0);
        setRange([0]);
        commitChange({ target: newValue ?? LightTarget.Lights, fanSpeed: 0, brightness: 0 });
    }

    const changeFanSpeed = (event: React.MouseEvent<HTMLElement>,
        newValue: number | null) => {
        setFanSpeed(newValue ?? 0);
        commitChange({ fanSpeed: newValue ?? 0 });
    }

    const changeIsDelta = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsDeltaChange(event.target.checked);
        setFanSpeed(0);
        setRange([0]);
        commitChange({ isDeltaChange: event.target.checked, fanSpeed: 0, brightness: 0 });
    }

    const changeMood = (newMood: LightMood) => {
        setMood(newMood);
        commitChange({ mood: newMood });
    }

    if (props.orientation === 'vertical') {
        return <Box sx={{ marginBottom: '10px', alignItems: 'center' }}>
            {!props.hideTime ?
                <TimeSetting
                    sx={{ marginBottom: '10px' }}
                    data={data}
                    setData={d => setData(d as LightSetpoint)}
                    deleteTime={props.deleteTime}
                    updateSetting={d => { setData(d as LightSetpoint); props.updateSetting(d); }} /> : null}
            {automation.fanState ?
                <ToggleButtonGroup
                    sx={{ marginRight: '10px', height: '40px' }}
                    exclusive
                    color="info"
                    value={target}
                    onChange={changeTarget}
                    size="small">
                    <Grey.CustomToggleButton sx={{ width: '65px' }} value={LightTarget.Lights}>
                        Lights
                    </Grey.CustomToggleButton>
                    <Grey.CustomToggleButton sx={{ width: '65px' }} value={LightTarget.Fan}>
                        Fan
                    </Grey.CustomToggleButton>
                </ToggleButtonGroup> : null}
            <br />
            <FormControlLabel sx={{ paddingLeft: '5px', paddingRight: '10px', paddingTop: '5px' }} control={<Switch checked={isDeltaChange} onChange={changeIsDelta} />} label="Incremental" />
            {target === LightTarget.Lights ?
                isDeltaChange ?
                    <DeltaChange key='lightdelta' format={value => `${value}%`} onChange={value => commitChange({ brightness: value })} value={range[0]} step={5} range={{ min: -100, max: 100 }} /> :
                    <React.Fragment>
                        <Grey.CustomSlider
                            color="primary"
                            disabled={props.data.oneTime === true}
                            min={0}
                            max={100}
                            orientation='horizontal'
                            step={1}
                            marks={marks}
                            value={range}
                            sx={{
                                marginLeft: '15px',
                                marginRight: '15px',
                                marginTop: '5px',
                                width: '250px',
                                '& .MuiSlider-markLabel[data-index="2"]': { color: 'green', background: `${theme.page.background}`, borderRadius: '20px', paddingX: '5px' },
                                '& .MuiSlider-markLabel[data-index="3"]': { color: '#f79d6d' }
                            }}
                            onChange={handleSliderChange}
                            onChangeCommitted={commitSliderChange}
                            valueLabelDisplay="auto"
                        />
                        {automation.supportedMoods.length > 0 ? <Stack direction='row' sx={{ alignItems: 'center' }}>
                            <Typography sx={{ marginRight: '10px' }}>Mood:</Typography>
                            <Select
                                value={mood}
                                onChange={x => changeMood(x.target.value as LightMood)}
                                label="Mood"
                            >
                                {Object.keys(LightMood).map(op => <MenuItem key={op} value={op}>{op}</MenuItem>)}
                            </Select>
                        </Stack> : null}

                    </React.Fragment>
                :
                isDeltaChange ? <DeltaChange key='fandelta' format={value => `${value}`} onChange={value => commitChange({ fanSpeed: value })} value={fanSpeed} step={1} range={{ min: -4, max: 4 }} /> :
                    <ToggleButtonGroup
                        sx={{ height: '40px' }}
                        exclusive
                        color="info"
                        value={fanSpeed}
                        onChange={changeFanSpeed}
                        size="small">
                        <Grey.CustomToggleButton value={0}>Off</Grey.CustomToggleButton>
                        {automation.fanState?.supportedSpeeds?.map(s => <Grey.CustomToggleButton sx={{ width: '35px' }} key={s} value={s}>{s}</Grey.CustomToggleButton>)}
                    </ToggleButtonGroup>
            }
        </Box>;
    }

    return <Box sx={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
        {!props.hideTime ?
            <TimeSetting
                sx={{}}
                data={data}
                setData={d => setData(d as LightSetpoint)}
                deleteTime={props.deleteTime}
                updateSetting={d => { setData(d as LightSetpoint); props.updateSetting(d); }} /> : null}
        {automation.fanState ?
            <ToggleButtonGroup
                sx={{ marginRight: '10px', height: '40px' }}
                exclusive
                color="info"
                value={target}
                onChange={changeTarget}
                size="small">
                <Grey.CustomToggleButton sx={{ width: '65px' }} value={LightTarget.Lights}>
                    Lights
                </Grey.CustomToggleButton>
                <Grey.CustomToggleButton sx={{ width: '65px' }} value={LightTarget.Fan}>
                    Fan
                </Grey.CustomToggleButton>
            </ToggleButtonGroup> : null}
        <FormControlLabel sx={{ paddingRight: '10px' }} control={<Switch checked={isDeltaChange} onChange={changeIsDelta} />} label="Incremental" />
        {target === LightTarget.Lights ?
            isDeltaChange ?
                <DeltaChange format={value => `${value}%`} onChange={value => commitChange({ brightness: value })} value={range[0]} step={5} range={{ min: -100, max: 100 }} /> :
                <React.Fragment>
                    <Grey.CustomSlider
                        color="primary"
                        disabled={props.data.oneTime === true}
                        min={0}
                        max={100}
                        orientation={props.orientation ?? "horizontal"}
                        step={1}
                        marks={marks}
                        value={range}
                        sx={{
                            width: '250px',
                            marginRight: '25px',
                            '& .MuiSlider-markLabel[data-index="2"]': { color: 'green', background: `${theme.page.background}`, borderRadius: '20px', paddingX: '5px' },
                            '& .MuiSlider-markLabel[data-index="3"]': { color: '#f79d6d' }
                        }}
                        onChange={handleSliderChange}
                        onChangeCommitted={commitSliderChange}
                        valueLabelDisplay="auto"
                    />
                    {automation.supportedMoods.length > 0 ? <Select
                        value={mood}
                        onChange={x => changeMood(x.target.value as LightMood)}
                        label="Mood"
                    >
                        {Object.keys(LightMood).map(op => <MenuItem key={op} value={op}>{op}</MenuItem>)}
                    </Select> : null}

                </React.Fragment>
            :
            isDeltaChange ? <DeltaChange format={value => `${value}`} onChange={value => commitChange({ fanSpeed: value })} value={fanSpeed} step={1} range={{ min: -4, max: 4 }} /> :
                <ToggleButtonGroup
                    sx={{ height: '40px' }}
                    exclusive
                    color="info"
                    value={fanSpeed}
                    onChange={changeFanSpeed}
                    size="small">
                    <Grey.CustomToggleButton sx={{}} value={0}>Off</Grey.CustomToggleButton>
                    {automation.fanState?.supportedSpeeds?.map(s => <Grey.CustomToggleButton sx={{ width: '35px' }} key={s} value={s}>{s}</Grey.CustomToggleButton>)}
                </ToggleButtonGroup>
        }
    </Box>;
}

const BlindsSetting: React.FC<IProps> = (props: IProps) => {
    const [data, setData] = useState(props.data as BlindsSetpoint);

    const [position, setPosition] = useState([data.position]);
    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        var dataArray = newValue as number[];
        setPosition(dataArray);
    }

    const commitChange = async (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
        var dataArray = newValue as number[];
        setData({ ...data, position: dataArray[0] });
        props.updateSetting({ ...data, position: dataArray[0] } as BlindsSetpoint)
    }

    const marks = [
        {
            value: 0,
            label: '0%'
        },
        {
            value: 100,
            label: '100%'
        },
        {
            value: position[0],
            label: position[0] + '%'
        }
    ];

    return <Box>
        {!props.hideTime ?
            <TimeSetting
                sx={{ marginY: '10px' }}
                data={data}
                setData={d => setData(d as BlindsSetpoint)}
                deleteTime={props.deleteTime}
                updateSetting={d => { setData(d as BlindsSetpoint); props.updateSetting(d); }} /> : null}
        <Slider
            color="primary"
            min={0}
            max={100}
            orientation="vertical"
            step={1}
            marks={marks}
            value={position}
            sx={{
                height: '280px',
                marginTop: .5,
                '& .MuiSlider-markLabel[data-index="2"]': { color: '#4ac6ff' },
                '& .MuiSlider-markLabel[data-index="3"]': { color: '#f79d6d' }
            }}
            onChange={handleSliderChange}
            onChangeCommitted={commitChange}
            valueLabelDisplay="auto"
        />
    </Box>;
}


const PlugSetting: React.FC<IProps> = (props: IProps) => {
    const [data, setData] = useState(props.data as PlugSetpoint);
    const automation = props.automation as IPlugsAutomationModel;

    console.log('mode')
    console.log(data.mode)
    const [mode, setMode] = useState(data.mode);
    const [plugId, setPlugId] = useState(data.plugId ?? 'default');

    const changeMode = (mode: PlugMode) => {
        setMode(mode);
        setData({ ...data, mode });
        props.updateSetting({ ...data, mode } as PlugSetpoint);
    }

    const changePlugId = (id: string) => {
        setPlugId(id);
        setData({ ...data, plugId: id });
        props.updateSetting({ ...data, plugId: id } as PlugSetpoint);
    }

    if (props.orientation === 'vertical') {
        return <Box>
            {!props.hideTime ?
                <TimeSetting
                    sx={{ marginY: '10px' }}
                    data={data}
                    setData={d => setData(d as PlugSetpoint)}
                    deleteTime={props.deleteTime}
                    updateSetting={d => { setData(d as PlugSetpoint); props.updateSetting(d); }} /> : null}
            <Select
                value={plugId}
                onChange={x => changePlugId(x.target.value as string)}
                label="Plug"
                sx={{ marginRight: '10px' }}
            >
                <MenuItem key='default' value='default'>Select...</MenuItem>
                {automation.plugs.map(p => <MenuItem key={p.entityId} value={p.entityId}>{p.name}</MenuItem>)}
            </Select>
            <br />
            <Select
                value={mode}
                onChange={x => changeMode(x.target.value as PlugMode)}
                label="Plug"
                sx={{ marginRight: '10px' }}
            >
                <MenuItem key='default' value='default'>Select...</MenuItem>
                {Object.keys(PlugMode).map(p => <MenuItem key={p} value={p}>{p}</MenuItem>)}
            </Select>
        </Box>;
    }

    return <Box>
        {!props.hideTime ?
            <TimeSetting
                sx={{ marginY: '10px' }}
                data={data}
                setData={d => setData(d as PlugSetpoint)}
                deleteTime={props.deleteTime}
                updateSetting={d => { setData(d as PlugSetpoint); props.updateSetting(d); }} /> : null}
        <Select
            value={plugId}
            onChange={x => changePlugId(x.target.value as string)}
            label="Plug"
            sx={{ marginRight: '10px' }}
        >
            <MenuItem key='default' value='default'>Select...</MenuItem>
            {automation.plugs.map(p => <MenuItem key={p.entityId} value={p.entityId}>{p.name}</MenuItem>)}
        </Select>
        <Select
            value={mode}
            onChange={x => changeMode(x.target.value as PlugMode)}
            label="Plug"
            sx={{ marginRight: '10px' }}
        >
            <MenuItem key='default' value='default'>Select...</MenuItem>
            {Object.keys(PlugMode).map(p => <MenuItem key={p} value={p}>{p}</MenuItem>)}
        </Select>
    </Box>;
}

const MediaPlayerSetting: React.FC<IProps> = (props: IProps) => {
    const automation = props.automation as IMediaPlayerAutomationModel;
    const [data, setData] = useState(props.data as MediaPlayerSetpoint);

    const [operation, setOperation] = useState(data.operation);
    const [operationData, setOperationData] = useState(data.operationData);
    const [actionIsVisible, setActionIsVisible] = useState(operation == MediaPlayerOperation.Action);

    const commitChange = (n: {}) => {
        setData({ ...data, ...n });
        props.updateSetting({ ...data, ...n })
    }

    const changeOperation = (op: MediaPlayerOperation) => {
        setOperation(op);
        setActionIsVisible(op == MediaPlayerOperation.Action);
        commitChange({ operation: op, operationData: operationData });
    }

    const changeAction = (action: string) => {
        setOperationData(action);
        commitChange({ operation: operation, operationData: action });
    }

    return <Box>
        {!props.hideTime ?
            <TimeSetting
                sx={{ marginY: '10px' }}
                data={data}
                setData={d => setData(d as MediaPlayerSetpoint)}
                deleteTime={props.deleteTime}
                updateSetting={d => { setData(d as MediaPlayerSetpoint); props.updateSetting(d); }} /> : null}
        <Select
            value={operation}
            onChange={x => changeOperation(x.target.value as MediaPlayerOperation)}
            label="Operation"
        >
            {Object.keys(MediaPlayerOperation).map(op => <MenuItem key={op} value={op}>{op}</MenuItem>)}
        </Select>
        <p />
        <Select
            sx={{ display: actionIsVisible ? '' : 'none' }}
            value={operationData}
            onChange={x => changeAction(x.target.value)}
            label="Data"
        >
            {automation.actions?.map(a => <MenuItem key={a.id} value={a.id}>{a.actionName}</MenuItem>)}
        </Select>
    </Box>;
}

const SceneSetting: React.FC<IProps> = (props: IProps) => {
    const automation = props.automation as IAutomationModel;
    const [data, setData] = useState(props.data as SceneSetpoint);

    const [scene, setScene] = useState(data.scene);

    const commitChange = (n: {}) => {
        setData({ ...data, ...n });
        props.updateSetting({ ...data, ...n })
    }

    const changeScene = (val: Scene) => {
        setScene(val);
        commitChange({ scene: val });
    }

    return <Box>
        {!props.hideTime ?
            <TimeSetting
                sx={{ marginY: '10px' }}
                data={data}
                setData={d => setData(d as SceneSetpoint)}
                deleteTime={props.deleteTime}
                updateSetting={d => { setData(d as SceneSetpoint); props.updateSetting(d); }} /> : null}
        <Select
            value={scene}
            onChange={x => changeScene(x.target.value as Scene)}
            label="Scene"
        >
            {Object.keys(Scene).map(op => <MenuItem key={op} value={op}>{op}</MenuItem>)}
        </Select>
        <p />
    </Box>;
}