import { IronSharp, PowerSettingsNewTwoTone, PowerSharp, PrintSharp, WarningTwoTone } from "@mui/icons-material";
import { Box, CardContent, FormControlLabel, Grid, Stack, Switch, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import { IPlugsAutomationModel, PlugState } from "../../models/Models";
import { appService } from "../../services/AppService";
import { useEventHub } from "../../services/EventHub";
import * as Grey from "../StyledComponents";
import { PanelProps } from "./PanelProps";
import { ComponentUnavailable } from "../controls/ComponentUnavailable";
import { SettingsMenuButton } from "../configs/SettingsMenuButton";

const icon = (plug: PlugState) => {
    if (plug.name.toLowerCase().includes('iron')) {
        return <IronSharp htmlColor={plug.isOn ? '#d9a536' : ''} />
    }

    if (plug.name.toLowerCase().includes('printer')) {
        return <PrintSharp htmlColor={plug.isOn ? '#d9a536' : ''} />
    }

    return <PowerSharp htmlColor={plug.isOn ? '#d9a536' : ''} />
};

function PlugStatus(data: IPlugsAutomationModel, plug: PlugState) {
    const togglePower = async () => {
        await appService.Plugs.toggle(data.id, plug.entityId);
    }

    return <Stack key={plug.entityId} direction='column' sx={{ position: 'relative', display: 'inline-flex', marginRight: '10px' }}>
        <Typography textAlign='center' sx={{}}>{plug.name}</Typography>
        <Grey.CustomIconButton onClick={togglePower}>{icon(plug)}</Grey.CustomIconButton>
    </Stack>
}

function PlugsPanelSmall(props: PanelProps) {
    const [data, setData] = useState(props.data as IPlugsAutomationModel);

    useEventHub<IPlugsAutomationModel>(data.id, setData);

    return <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Grey.CardTitleButton onClick={props.handleOpen}>
            <Typography className='smallCardTitle' color='secondary' fontSize='14px'>
                {data?.title}
            </Typography>
        </Grey.CardTitleButton>
        <Box sx={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px', marginBottom: '10px', textAlign: 'left' }}>
            {data.plugs.map(i => PlugStatus(data, i))}
        </Box>
    </Box>
}

function PlugView(props: { data: IPlugsAutomationModel, plug: PlugState }) {
    const data = props.data;
    const [plug, setPlug] = useState(props.plug);

    useEffect(() => {
        setPlug(props.plug);
    }, [props.plug]);

    const togglePower = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const result = await appService.Plugs.turnOn(data.id, plug.entityId);
            const updatedPlug = result?.plugs.find(i => i.entityId == plug.entityId);
            if (updatedPlug) setPlug(updatedPlug)
        }
        else {
            const result = await appService.Plugs.turnOff(data.id, plug.entityId);
            const updatedPlug = result?.plugs.find(i => i.entityId == plug.entityId);
            if (updatedPlug) setPlug(updatedPlug)
        }
    }

    if (!plug.isAvailable) {
        return <ComponentUnavailable name={plug.name} sx={{ marginTop: '15px' }} />
    }

    return <Grid container spacing={1} alignItems="center" marginY='1px' marginRight='5px'>
        <FormControlLabel control={<Switch color='warning' checked={plug.isOn ?? false} onChange={togglePower} />} label={plug.name} />
        <Typography sx={{ marginLeft: '0px' }}>{Math.round(plug.kwh * 100) / 100} kWh (${Math.round(plug.cost * 100) / 100})</Typography>
    </Grid>
}

function PlugsPanel(props: PanelProps) {

    const [data, setData] = useState(props.data as IPlugsAutomationModel);
    const theme = useTheme();

    useEventHub<IPlugsAutomationModel>(data.id, setData);

    return (
        <Grey.AutomationCard className={`card card-${theme.name}`} variant="outlined">
            <CardContent>
                <Grey.AutomationTitle>
                    {data.title}
                    <SettingsMenuButton automation={data} />
                </Grey.AutomationTitle>
                <div style={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: 14, textAlign: 'left' }} color="text.secondary" gutterBottom>
                        {data.status}
                    </Typography>
                    <Box sx={{ marginTop: '0px', marginLeft: '10px' }}>
                        {data.plugs.map(plug => <PlugView key={plug.entityId} data={data} plug={plug} />)}
                    </Box>
                </div>
            </CardContent>
        </Grey.AutomationCard>
    );
}
export { PlugsPanel, PlugsPanelSmall };

