import { AddTwoTone, Delete, Edit, EditTwoTone } from "@mui/icons-material";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AutomationType, DayConfigModel, DayOfWeek, IAutomationModel, LightSetpoint, LightTarget, ScheduleConfigModel, SetpointSetting } from "../../models/Models";
import { configService } from "../../services/ConfigService";
import { UseWindowDimensions } from "../../services/Utils";
import * as Grey from "../StyledComponents";
import { GetSetpointDescription, GetSetpointDescriptionWithTime, SetpointSettingControl } from "./SetpointSettingControl";

interface IData {
    automation: IAutomationModel,
    automationId: string,
    type: AutomationType,
    data: DayConfigModel,
    update: (c: ScheduleConfigModel) => void,
    onDelete: (dayOfWeek: DayOfWeek, setting: SetpointSetting) => Promise<ScheduleConfigModel | null>,
    isMobile: boolean;
}

export function CreateNewSetpoint(type: AutomationType | string): SetpointSetting {
    const date = dayjs().add(1, 'hour');
    if (type === AutomationType.Climate) {
        return { $type: "Climate", id: "dummy", milliseconds: date.hour() * 60 * 60 * 1000, timeOfDay: `${date.format('HH')}:00:00` };
    }
    else if (type === AutomationType.Lights) {
        return { $type: "Lights", id: "dummy", milliseconds: date.hour() * 60 * 60 * 1000, timeOfDay: `${date.format('HH')}:00:00`, target: LightTarget.Lights } as LightSetpoint;
    }
    else if (type === AutomationType.Blinds) {
        return { $type: "Blinds", id: "dummy", milliseconds: date.hour() * 60 * 60 * 1000, timeOfDay: `${date.format('HH')}:00:00` };
    }
    else if (type === AutomationType.MediaPlayer) {
        return { $type: "MediaPlayer", id: "dummy", milliseconds: date.hour() * 60 * 60 * 1000, timeOfDay: `${date.format('HH')}:00:00` };
    }
    else if (type === AutomationType.Plugs) {
        return { $type: "Plug", id: "dummy", milliseconds: date.hour() * 60 * 60 * 1000, timeOfDay: `${date.format('HH')}:00:00` };
    }
    else if (type === "Scene") {
        return { $type: "Scene", id: "dummy", milliseconds: date.hour() * 60 * 60 * 1000, timeOfDay: `${date.format('HH')}:00:00` };
    }
    else {
        return { $type: "Unknown", id: "", milliseconds: 0 }
    }
}

function SmallSettingView(props: { automation: IAutomationModel, day: DayConfigModel, setting: SetpointSetting, onDelete: (setting: SetpointSetting) => void }) {

    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [description, setDescription] = useState(GetSetpointDescriptionWithTime(props.automation, props.setting));
    const [setting, setSetting] = useState(props.setting);
    const [draftSetting, setDraftSetting] = useState(props.setting);

    const updateSetting = (setting: SetpointSetting) => {
        setDraftSetting(setting);
    }

    const commitSetting = async () => {
        await configService.updateTime(props.automation.id, props.day.dayOfWeek, draftSetting);
        setSetting(draftSetting);
        setDescription(GetSetpointDescriptionWithTime(props.automation, draftSetting))
        setOpen(false);
    }

    return <Box sx={{ width: '100%' }}>
        <Stack direction='row' sx={{ justifyContent: 'space-between', marginY: '5px', width: '100%', border: '1px solid rgba(25, 118, 210, 0.5)', borderRadius: '5px' }}>
            <Grey.CustomButton sx={{ width: '100%', justifyContent: 'left', paddingY: '10px' }} onClick={() => setOpen(true)} ><Typography>{description}</Typography></Grey.CustomButton>
            <Grey.CustomIconButton sx={{ borderRadius: '0' }} onClick={() => setOpenDelete(true)}><Delete /></Grey.CustomIconButton>
        </Stack>
        <Dialog
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: '100%',
                        maxWidth: "800px",
                    },
                },
            }}
            open={open}
            onClose={() => setOpen(false)}>
            <DialogContent sx={{ padding: '0' }}>
                <Box sx={{ margin: '10px' }}>
                    <SetpointSettingControl
                        automation={props.automation}
                        data={setting}
                        orientation="vertical"
                        updateSetting={updateSetting} />
                </Box>
            </DialogContent>
            <DialogActions sx={{ marginX: '5px' }}>
                <Grey.CustomButton variant='outlined' onClick={() => setOpen(false)}>Cancel</Grey.CustomButton>
                <Grey.CustomButton variant='outlined' onClick={() => commitSetting()}>Save</Grey.CustomButton>
            </DialogActions>
        </Dialog>
        <Dialog
            open={openDelete}
            onClose={() => setOpenDelete(false)}>
            <DialogTitle id="alert-dialog-title">
                {"Delete?"}
            </DialogTitle>
            <DialogContent>
            </DialogContent>
            <DialogActions>
                <Grey.CustomButton onClick={() => setOpenDelete(false)}>Cancel</Grey.CustomButton>
                <Grey.CustomButton onClick={() => props.onDelete(setting)} autoFocus>Delete</Grey.CustomButton>
            </DialogActions>
        </Dialog>
    </Box>
}

function AddSetpointDialog(props: { automation: IAutomationModel, open: boolean, onClose: () => void, onAdd: (setting: SetpointSetting) => void }) {

    const [draftSetting, setDraftSetting] = useState(CreateNewSetpoint(props.automation.type));

    const update = (setting: SetpointSetting) => {
        setDraftSetting(setting);
    }

    return <Dialog
        sx={{
            "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                    width: '100%',
                    maxWidth: "800px",
                },
            },
        }}
        open={props.open}
        onClose={props.onClose}>
        <DialogContent sx={{ padding: '0' }}>
            <Box sx={{ margin: '10px' }}>
                <SetpointSettingControl
                    automation={props.automation}
                    data={draftSetting}
                    orientation="vertical"
                    updateSetting={update} />
            </Box>
        </DialogContent>
        <DialogActions sx={{ marginX: '5px' }}>
            <Grey.CustomButton variant='outlined' onClick={props.onClose}>Cancel</Grey.CustomButton>
            <Grey.CustomButton variant='outlined' onClick={() => { props.onAdd(draftSetting); }}>Save</Grey.CustomButton>
        </DialogActions>
    </Dialog>
}

export function ScheduleDay(props: IData) {
    const [data, setData] = useState(props.data);
    const [addOpen, setAddOpen] = useState(false);
    const [settings, setSettings] = useState(() => data.settings.map(item => item));
    const navigate = useNavigate();

    const addTime = async (s: SetpointSetting) => {
        setAddOpen(false);
        var updated = await configService.addTime(props.automationId, data.dayOfWeek, s);
        if (updated) {
            setSettings([...settings, updated])
        }
    }

    const deleteTime = async (setting: SetpointSetting) => {
        setSettings([]);
        await props.onDelete(data.dayOfWeek, setting);
        const newSettings = [...settings.filter(i => i.id !== setting.id)];
        setSettings(newSettings);
    }

    const updateSetting = async (setting: SetpointSetting) => {
        await configService.updateTime(props.automationId, data.dayOfWeek, setting);
        setData({ ...data, settings: settings.map(i => i.id == setting.id ? setting : i) })
        setSettings(settings.map(i => i.id == setting.id ? setting : i));
    }

    if (props.isMobile) {
        return (
            <Box>
                <Stack direction='column' sx={{ marginX: '10px' }}>
                    {settings.map((i, index) => <SmallSettingView key={index} automation={props.automation} day={data} setting={i} onDelete={deleteTime} />)}
                </Stack>
                <Stack direction='row' sx={{ position: 'absolute', bottom: 30, right: 30 }}>
                    <Grey.CustomButton variant="contained" onClick={() => setAddOpen(true)} sx={{ marginRight: '20px' }}>Add Action</Grey.CustomButton>
                    <Grey.CustomButton variant="contained" onClick={() => navigate(-1)}>Done</Grey.CustomButton>
                </Stack>
                <AddSetpointDialog automation={props.automation} onAdd={addTime} open={addOpen} onClose={() => setAddOpen(false)} />
            </Box >
        )
    }
    if (props.automation.type === AutomationType.Lights) {
        return (
            <Box>
                {settings.map((i, index) => <SetpointSettingControl key={index} automation={props.automation} dayOfWeek={data.dayOfWeek} data={i} updateSetting={(c) => updateSetting(c)} deleteTime={id => deleteTime(id)} />)}
                <Grey.CustomIconButton sx={{ height: '40px' }} onClick={() => addTime(CreateNewSetpoint(props.type))}><AddTwoTone /></Grey.CustomIconButton>
            </Box>
        )
    }
    return (
        <div className='panel' style={{ height: '400px', paddingBottom: '100px', overflowX: 'auto', overflowY: 'hidden' }}>
            {settings.map((i, index) => <SetpointSettingControl key={index} automation={props.automation} dayOfWeek={data.dayOfWeek} data={i} updateSetting={(c) => updateSetting(c)} deleteTime={id => deleteTime(id)} />)}
            <Grey.CustomIconButton sx={{ height: '40px' }} onClick={() => addTime(CreateNewSetpoint(props.type))}><AddTwoTone /></Grey.CustomIconButton>
        </div>
    );
}