import dayjs from "dayjs";
import { ESThermostat, IAutomationModel, IBlindsAutomationModel, ICameraModel, IClimateAutomationModel, IContactSensorsAutomationModel, IEventLogData, IGarageAutomationModel, ILightAutomationModel, ILockAutomationModel, IMediaPlayerAutomationModel, IPlugsAutomationModel, ITimersAutomationModel, LightMood, MediaItem, MediaList, OperationMode, Scene, SceneModel, SetpointSetting, Watchlist } from "../models/Models";

export class AppService {
    public static Host = 'https://api.thinkgrey.ai/';

    public Automations: AutomationService;
    public ContactSensor: ContactSensorService;
    public Lights: LightService;
    public Climate: ClimateService;
    public Locks: LockService;
    public MediaPlayers: MediaPlayerService;
    public Covers: CoverService;
    public Scenes: SceneService;
    public Plugs: PlugService;
    public Printer: PrinterService;
    public Watchlist: WatchlistService;
    public IR: IRService;

    constructor() {
        this.Automations = new AutomationService();
        this.ContactSensor = new ContactSensorService();
        this.Lights = new LightService();
        this.Climate = new ClimateService();
        this.Locks = new LockService();
        this.MediaPlayers = new MediaPlayerService();
        this.Covers = new CoverService();
        this.Scenes = new SceneService();
        this.Plugs = new PlugService();
        this.Printer = new PrinterService();
        this.Watchlist = new WatchlistService();
        this.IR = new IRService();
    }

    public async getCameras(): Promise<ICameraModel[]> {
        const response = await fetch(AppService.Host + 'api/camera');
        return await response.json();
    }

    public async getCameraFiles(id: string): Promise<MediaList> {
        const response = await fetch(AppService.Host + `api/camera/${id}/files`);
        return response.ok ? await response.json() : null;
    }

    public async castCamera(id: string): Promise<boolean> {
        const response = await fetch(AppService.Host + `api/camera/${id}/cast`, { method: 'POST' });
        return response.ok;
    }

    public async resolveAlerts(id: string): Promise<IMediaPlayerAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Alerts/${id}/resolveAll`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async resolveAlert(id: string, alertId: string): Promise<IMediaPlayerAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Alerts/${id}/resolve?alertId=${alertId}`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async startTimer(id: string, timerId: string): Promise<IMediaPlayerAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Timers/${id}/start?timerId=${timerId}`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async cancelTimer(id: string, timerId: string): Promise<ITimersAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Timers/${id}/cancel?timerId=${timerId}`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async getEvents(): Promise<IEventLogData[]> {
        const response = await fetch(AppService.Host + 'api/Automation/events');
        return await response.json();
    }
}

export class AutomationService {

    public async getAutomations(dashboard?: string): Promise<IAutomationModel[]> {
        if (dashboard) {
            const response = await fetch(AppService.Host + `api/Automation?dashboard=${dashboard}`);
            return response.ok ? await response.json() : [];
        }
        else {
            const response = await fetch(AppService.Host + `api/Automation`);
            return response.ok ? await response.json() : [];
        }
    }

    public async getAutomation(id: string): Promise<IAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Automation/${id}`);
        return response.ok ? await response.json() : null;
    }

    public async pauseAutomation(id: string): Promise<IAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Automation/${id}/pause`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async getHistory(id: string, sensorId?: string | null, start?: dayjs.Dayjs, end?: dayjs.Dayjs): Promise<ESThermostat[] | null> {
        const sensor = sensorId ? `&sensorId=${sensorId}` : '';
        const response = await fetch(AppService.Host + `api/History/${id}?${start ? ("start=" + start?.format()) : ""}${end ? ("&end=" + end?.format()) : ""}${sensor}`);
        return response.ok ? await response.json() : null;
    }
}

export class ContactSensorService {

    public async calibrateClosed(id: string, entityId: string): Promise<IContactSensorsAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/ContactSensor/${id}/${entityId}/calibrateClosed`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }
}

export class LightService {

    public async off(id: string): Promise<ILightAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Lights/${id}/off`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async toggle(id: string): Promise<ILightAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Lights/${id}/toggle`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async toggleComponent(id: string, lightId: string): Promise<ILightAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Lights/${id}/${lightId}/toggle`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async incrementBrightness(id: string, brightness: number): Promise<ILightAutomationModel | null> {
        const response = await fetch(AppService.Host + 'api/Lights/' + id + '/incrementBrightness?delta=' + brightness, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async changeBrightness(id: string, brightness: number): Promise<ILightAutomationModel | null> {
        const response = await fetch(AppService.Host + 'api/Lights/' + id + '/brightness?brightness=' + brightness, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async changeColorTemp(id: string, temp: number): Promise<ILightAutomationModel | null> {
        const response = await fetch(AppService.Host + 'api/Lights/' + id + '/temperature?temperature=' + temp, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async changeMood(id: string, mood: LightMood): Promise<ILightAutomationModel | null> {
        const response = await fetch(AppService.Host + 'api/Lights/' + id + '/changeMood?mood=' + mood, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async changeColor(id: string, color: string): Promise<ILightAutomationModel | null> {
        const response = await fetch(AppService.Host + 'api/Lights/' + id + `/changeColor?color=${color}`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async changeMoodString(id: string, mood: string): Promise<ILightAutomationModel | null> {
        const response = await fetch(AppService.Host + 'api/Lights/' + id + '/changeMood?mood=' + mood, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async fanToggle(id: string): Promise<ILightAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Lights/${id}/fanToggle`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async fanOn(id: string, isOn: boolean): Promise<ILightAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Lights/${id}/fanOn?isOn=${isOn}`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async fanSpeed(id: string, speed: number): Promise<ILightAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Lights/${id}/fanSpeed?speed=${speed}`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async onUntil(id: string, minutes: number): Promise<ILightAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Lights/${id}/onUntil?minutes=${minutes}`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }
}

export class ClimateService {
    public async setAutoTemperature(id: string, min: number, max: number): Promise<IClimateAutomationModel | null> {
        const response = await fetch(AppService.Host + 'api/Climate/' + id + '/setAutoTemp?min=' + min + '&max=' + max, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async increaseHold(id: string, minutes: number): Promise<IClimateAutomationModel | null> {
        const response = await fetch(AppService.Host + 'api/Climate/' + id + '/increaseHold?minutes=' + minutes, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async changeMode(id: string, operationMode: OperationMode): Promise<IClimateAutomationModel | null> {
        const response = await fetch(AppService.Host + 'api/Climate/' + id + '/changemode?mode=' + operationMode, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }
}

export class LockService {

    public async lock(id: string): Promise<ILockAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Locks/${id}/lock`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async unlock(id: string): Promise<ILockAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Locks/${id}/unlock`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async unlockUntil(id: string, lockInMinutes: number): Promise<ILockAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Locks/${id}/unlockUntil?lockInMinutes=${lockInMinutes}`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }
}

export class PlugService {

    public async turnOff(id: string, plugId: string): Promise<IPlugsAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Plugs/${id}/turnOff/${plugId}`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async turnOn(id: string, plugId: string): Promise<IPlugsAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Plugs/${id}/turnOn/${plugId}`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async toggle(id: string, plugId: string): Promise<IPlugsAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Plugs/${id}/toggle/${plugId}`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }
}

export class PrinterService {

    public async pause(id: string): Promise<IPlugsAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Printer/${id}/pause`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async resume(id: string): Promise<IPlugsAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Printer/${id}/resume`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async stop(id: string): Promise<IPlugsAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Printer/${id}/stop`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async turnOff(id: string): Promise<IPlugsAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Printer/${id}/turnOff`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async turnOn(id: string): Promise<IPlugsAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Printer/${id}/turnOn`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async togglePower(id: string): Promise<IPlugsAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Printer/${id}/togglePower`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async cast(id: string): Promise<IPlugsAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Printer/${id}/cast`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async toggleLight(id: string, isOn: boolean): Promise<IGarageAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Printer/${id}/toggleLight?isOn=${isOn}`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }
}

export class MediaPlayerService {

    public async executeAction(id: string, actionId: string): Promise<IMediaPlayerAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/MediaPlayer/${id}/executeAction/${actionId}`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async togglePause(id: string): Promise<IMediaPlayerAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/MediaPlayer/${id}/togglePause`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async turnOff(id: string): Promise<IMediaPlayerAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/MediaPlayer/${id}/turnOff`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async turnOn(id: string): Promise<IMediaPlayerAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/MediaPlayer/${id}/turnOn`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async setVolume(id: string, volume: number): Promise<IMediaPlayerAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/MediaPlayer/${id}/setVolume?volume=${volume}`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }
}

export class WatchlistService {

    public async get(): Promise<Watchlist | null> {
        const response = await fetch(AppService.Host + `api/Watchlist`, { method: 'GET' });
        return response.ok ? await response.json() : null;
    }

    public async autocomplete(s: string): Promise<MediaItem[] | null> {
        const response = await fetch(AppService.Host + `api/Watchlist/autocomplete?s=${s}`, { method: 'GET' });
        return response.ok ? await response.json() : null;
    }

    public async add(item: MediaItem): Promise<Watchlist | null> {
        const response = await fetch(AppService.Host + `api/Watchlist/add`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }, method: 'POST', body: JSON.stringify(item)
        });
        return response.ok ? await response.json() : null;
    }

    public async addById(id: string): Promise<Watchlist | null> {
        const response = await fetch(AppService.Host + `api/Watchlist/addById?id=${id}`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async addByTitle(title: string): Promise<Watchlist | null> {
        const response = await fetch(AppService.Host + `api/Watchlist/addByTitle?title=${title}`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async delete(id: string): Promise<Watchlist | null> {
        const response = await fetch(AppService.Host + `api/Watchlist/${id}`, { method: 'DELETE' });
        return response.ok ? await response.json() : null;
    }
}

export class CoverService {

    public async close(id: string): Promise<IGarageAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Cover/${id}/close`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async open(id: string): Promise<IGarageAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Cover/${id}/open`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async toggleLight(id: string, isOn: boolean): Promise<IGarageAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/Cover/${id}/toggleLight?isOn=${isOn}`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async setPosition(id: string, position: number, entityId?: string): Promise<IBlindsAutomationModel | null> {
        if (entityId) {
            const response = await fetch(AppService.Host + `api/Cover/${id}/setPosition?position=${position}&entityId=${entityId}`, { method: 'POST' });
            return response.ok ? await response.json() : null;
        }
        else {
            const response = await fetch(AppService.Host + `api/Cover/${id}/setPosition?position=${position}`, { method: 'POST' });
            return response.ok ? await response.json() : null;
        }
    }
}

export class SceneService {

    public async get(): Promise<SceneModel | null> {
        const response = await fetch(AppService.Host + `api/scene`, { method: 'GET' });
        return response.ok ? await response.json() : null;
    }

    public async activate(scene: Scene): Promise<SceneModel | null> {
        const response = await fetch(AppService.Host + `api/scene/change?scene=${scene}`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async toggle(scene: string): Promise<SceneModel | null> {
        const response = await fetch(AppService.Host + `api/scene/toggle?scene=${scene}`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }
}

export class IRService {

    public async delete(id: string, sensorId: string, irCode: string): Promise<IAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/IR/${id}/${sensorId}/${irCode}`, { method: 'DELETE' });
        return response.ok ? await response.json() : null;
    }

    public async cancelLearn(id: string, sensorId: string): Promise<IAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/IR/${id}/${sensorId}/cancelLearn`, { method: 'POST' });
        return response.ok ? await response.json() : null;
    }

    public async learn(id: string, sensorId: string, setpoint: SetpointSetting): Promise<IAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/IR/${id}/${sensorId}/learn`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(setpoint)
        });
        return response.ok ? await response.json() : null;
    }

    public async add(id: string, sensorId: string, setpoint: SetpointSetting): Promise<IAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/IR/${id}/${sensorId}/add`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(setpoint)
        });
        return response.ok ? await response.json() : null;
    }

    public async update(id: string, sensorId: string, irCode: string, setpoint: SetpointSetting): Promise<IAutomationModel | null> {
        const response = await fetch(AppService.Host + `api/IR/${id}/${sensorId}/update/${irCode}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(setpoint)
        });
        return response.ok ? await response.json() : null;
    }
}

export const appService = new AppService();
